import "./main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Main from "./Main";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS
import ProductsContext from "./context/ProductsContext";
import CategoryBanner from "./context/CategoryBanner";

function App() {
  
  // axios.defaults.baseURL = 'https://shreemahalasa.com/admin/api'

  axios.defaults.baseURL = 'https://shreemahalasa.in/admin/public/api'
  // axios.defaults.baseURL = 'https://sm.sumagotest.in/public/api'
  // axios.defaults.baseURL = "http://localhost/shree_mahalasa/public/api";
  return (
    <>
      <div className="">
        <ProductsContext>
          <CategoryBanner>
          <Main />


          </CategoryBanner>
        </ProductsContext>
      </div>
    </>
  );
}

export default App;
