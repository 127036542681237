import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import { useParams } from "react-router-dom";
function Header() {
  const { id } = useParams();
  const [popular, setData] = useState([]); // Initialize data as an empty array
  const location = useLocation();

  
  useEffect(() => {
    // Define the API URL you want to fetch data from
    const apiUrl = "/get_maincategory"; // Replace with your API URL

    // Define an asynchronous function to fetch the data
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // The empty dependency array ensures this effect runs only once


  // useEffect(() => {
  //   // Define the API URL you want to fetch data from
  //   const apiUrl = "/get_maincategory"; // Replace with your API URL

  //   // Make the API request using Axios
  //   try {
  //     axios.get(apiUrl).then((response) => {
  //       setData(response.data.data);
  //     });
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }, []);



  return (
    <>
     {/* fixed-top */}
      <Container fluid className="navbr">
        <Navbar
          key="lg"
          expand="lg"
          className="font-18 font-weight-semibold navh"
          style={{ background: "#fff" }}
        >
        
     
          <Navbar.Brand href="#" className="ms-5 font-18 font-weight-semibold headercontactcolour" >
            Call Us : 
            <a style={{colour:'#0000'}} href={`tel:+917272004343`}>+917272004343</a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}  />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                SHREE MAHALSA
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 m-1 headerLink">
                <Link
                  className={`  navbtn d-flex align-items-center   font-family  ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  to="/"
                >
                  HOME
                </Link>
                <Link
                  className={`  navbtn d-flex align-items-center   font-family ${
                    location.pathname === "/about" ? "active" : ""
                  }`}
                  to="/about"
                >
                  ABOUT US
                </Link>
                {popular.map((item) => (
                  <Link
                    key={item.id}
                    className={`  navbtn d-flex align-items-center   font-family ${
                      location.pathname === `/categorydetails/${item.id}`
                        ? "active"
                        : ""
                    }`}
                    to={`/categorydetails/${item.id}`}
                   
                  >
                    {item.title}
                  </Link>
                ))}
                <Link
                  className={`  navbtn d-flex align-items-center   font-family ${
                    location.pathname === "/brands" ? "active" : ""
                  }`}
                  to="/brands"
                >
                  BRANDS
                </Link>
                <Link
                  className={`  navbtn d-flex align-items-center   font-family ${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                  to="/contact"
                >
                  CONTACT US
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </Container>
    </>
  );
}

export default Header;
