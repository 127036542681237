import React, { useState, useEffect } from "react";
import ContactIcon from "../assets/images/footer/Group 3429.png";
import AddressIcon from "../assets/images/footer/Group 3428.png";
import MailIcon from "../assets/images/footer/Group 3427.png";
import Logo from "../assets/images/logo/1687931517124H 11.png";
import SMLogo from "../assets/images/logo_sm.png";
import TringleIcon from "../assets/images/footer/tringleicon.png";
import RedHeart from "../assets/images/footer/red-heart 1.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const initialFormData = {
  email: "",
};

const Footer = () => {
  const [data, setData] = useState([]); // Initialize data as an empty array
  const [contactDetails, setContactDetails] = useState([]); // Initialize contactDetails as an empty array
  const [socialLinks, setSocialLinks] = useState([]); // Initialize socialLinks as an empty array
  const [mainCategory, setMainCategory] = useState([]); // Initialize mainCategory as an empty array

  // useEffect(() => {
  //   // Define the API URLs for fetching data
  //   const quickLinksUrl = "/get_quick_links";
  //   const companyDetailsUrl = "/get_company_details";
  //   const socialLinkUrl = "/get_socialmedia_links"; // Replace with your API URL for social links
  //   const maincategoryUrl = "/get_maincategory"; // Replace with your API URL for main categories

  //   // Fetch quick links data using Axios
  //   try {
  //     axios.get(quickLinksUrl).then((response) => {
  //       setData(response.data.data);
  //     });
  //   } catch (error) {
  //     console.error("Error fetching quick links data:", error);
  //   }

  //   // Fetch company details data using Axios
  //   try {
  //     axios.get(companyDetailsUrl).then((response) => {
  //       setContactDetails(response.data.data);
  //     });
  //   } catch (error) {
  //     console.error("Error fetching company details data:", error);
  //   }

  //   // Fetch social links data using Axios
  //   try {
  //     axios.get(socialLinkUrl).then((response) => {
  //       setSocialLinks(response.data.data);
  //     });
  //   } catch (error) {
  //     console.error("Error fetching social links data:", error);
  //   }

  //   // Fetch main categories data using Axios
  //   try {
  //     axios.get(maincategoryUrl).then((response) => {
  //       setMainCategory(response.data.data);
  //     });
  //   } catch (error) {
  //     console.error("Error fetching main categories data:", error);
  //   }
  // }, []);

  useEffect(() => {
    // Define the API URLs for fetching data
    const quickLinksUrl = "/get_quick_links";
    const companyDetailsUrl = "/get_company_details";
    const socialLinkUrl = "/get_socialmedia_links"; // Replace with your API URL for social links
    const maincategoryUrl = "/get_maincategory"; // Replace with your API URL for main categories

    const fetchData = async () => {
      try {
        // Fetch quick links data using Axios
        const quickLinksResponse = await axios.get(quickLinksUrl);
        setData(quickLinksResponse.data.data);

        // Fetch company details data using Axios
        const companyDetailsResponse = await axios.get(companyDetailsUrl);
        setContactDetails(companyDetailsResponse.data.data);

        // Fetch social links data using Axios
        const socialLinkResponse = await axios.get(socialLinkUrl);
        setSocialLinks(socialLinkResponse.data.data);

        // Fetch main categories data using Axios
        const mainCategoryResponse = await axios.get(maincategoryUrl);
        setMainCategory(mainCategoryResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [formData, setFormData] = useState(initialFormData);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true); // Show validation errors if the form is invalid
        return;
      }

      // Make a POST request to your API endpoint
      const response = await axios.post("/add_newsletter", formData);

      // Handle success
      console.log("Thank You, We Will Connect You Soon :", response.data);
      setSubmissionStatus("success");

      // Clear the form after successful submission
      setFormData(initialFormData);
      setValidated(false); // Reset validation state

      setTimeout(() => {
        setSubmissionStatus(null);
      }, 2000);
    } catch (error) {
      // Handle error
      console.error("Error sending data:", error);
      setSubmissionStatus("error");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row footer-main position-relative">
          <div className="col-lg-8 col-md-12 col-sm-12 footer-left card-size ">
            <div className="footer-card font-family">
              <div className="row align-items-center">
                {contactDetails.length === 0 ? (
                  <div className="col-lg-7 col-md-7 col-sm-7 mob-padding">
                    <h5>No Data Found For Website Contact.</h5>
                  </div>
                ) : (
                  contactDetails.map((item) => (
                    <div
                      className="col-lg-7 col-md-7 col-sm-7 mob-padding"
                      key={item.id}
                    >
                      <p className="font-33 font-46 font-weight-medium font-family">
                        Contact Us
                      </p>
                      <div className="row p-1">
                        <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                          <div className="col-lg-2 col-md-2 col-sm-2">
                            <img
                              src={ContactIcon}
                              className="icon-size-contact"
                              alt=""
                            />
                          </div>
                          <div className="col-lg-10 col-md-10 col-sm-10 contact-mob-space contactwebcolour">
                            <span className="font-weight-light font-15 font-family ">
                              {/* {item.phone_no} */}
                              <a href={`tel:${item.phone_no}`}>
                                {item.phone_no}
                              </a>
                            </span>
                            <a href={`https://wa.me/${item.phone_no}`}
                            target="_blank"
                            className="btn-whatsapp-pulse">
        <i className="fa fa-whatsapp"></i>
      </a>
                          </div>
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-12 col-md-12 col-sm-12 d-flex ">
                          <div className="col-lg-2 col-md-2 col-sm-2 align-items-start">
                            <img
                              src={AddressIcon}
                              className="icon-size"
                              alt=""
                            />
                          </div>
                          <div className="col-lg-10 col-md-10 col-sm-10 align-items-center contact-mob-space">
                            <span className="font-weight-light font-15 font-family ">
                              {item.address}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-12 col-md-12 col-sm-12 d-flex  align-items-center">
                          <div className="col-lg-2 col-md-2 col-sm-2">
                            <img src={MailIcon} className="icon-size" alt="" />
                          </div>
                          <div className="col-lg-10 col-md-10 col-sm-10 contact-mob-space contactwebcolour">
                            <span className="font-weight-light font-15 font-family ">
                              <a href={`mailto:${item.email}`}>{item.email}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}

                <div className="col-lg-5 col-md-12 col-sm-12 padding-card">
                  <div className="sub-card">
                    <div className="row  d-flex justify-content-center  align-items-center">
                      <div className="logo-icon">
                        <Link to={`/`}>
                          <img src={Logo} className="pb-2 img-fluid" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-around">
                      <ul className="horizontal-menu">
                        <li>
                          <span className="font-weight-semibold font-18">
                            Home
                          </span>
                          <ul>
                            <li className="font-weight-light font-15 font-11 font-family cursor-pointer">
                              <img
                                src={TringleIcon}
                                className="icon-tringle"
                                alt=""
                              />{" "}
                              <Link to={`/`}>HOME</Link>
                            </li>
                            <li className="font-weight-light font-15 font-11 font-family cursor-pointer">
                              <img
                                src={TringleIcon}
                                className="icon-tringle"
                                alt=""
                              />{" "}
                              <Link to={`/about`}>ABOUT US</Link>
                            </li>
                            <li className="font-weight-light font-15 font-11 font-family cursor-pointer">
                              <img
                                src={TringleIcon}
                                className="icon-tringle"
                                alt=""
                              />{" "}
                              <Link to={`/brands`}>BRANDS</Link>
                            </li>
                            <li className="font-weight-light font-15 font-11 font-family cursor-pointer">
                              <img
                                src={TringleIcon}
                                className="icon-tringle"
                                alt=""
                              />{" "}
                              <Link to={`/contact`}>CONTACT US</Link>
                            </li>
                            {/* <li  className="font-weight-light font-15 font-family cursor-pointer"><img src={TringleIcon} className="icon-tringle" alt="" /> Our brands</li>
      <li  className="font-weight-light font-15 font-family cursor-pointer"><img src={TringleIcon} className="icon-tringle" alt="" /> Shops</li> */}
                          </ul>
                        </li>
                        <li>
                          <span className="font-weight-semibold font-18 "></span>
                          <ul className="mt-4 ">
                            {mainCategory.map((item) => (
                              <Link
                                key={item.id}
                                className="font-weight-light font-15 font-11 font-family cursor-pointer mob-quick-link"
                                to={`/categorydetails/${item.id}`}
                              >
                                <img
                                  src={TringleIcon}
                                  className="icon-tringle"
                                  alt=""
                                />{" "}
                                {item.title}
                              </Link>
                            ))}
                            {/* {data.map((a, index) => (
  <li key={index} className="font-weight-light font-15 font-family cursor-pointer">
    <a href={a.link} target="_blank" rel="noopener noreferrer">
      <img src={a.image} className="icon-tringle" alt="" />
      &nbsp;{a.title}
    </a>
  </li>
))} */}
                          </ul>
                        </li>
                        <li>
                          <span className="font-weight-semibold font-18 p-1">
                            Follow us on
                          </span>
                          <ul className="d-flex">
                            {socialLinks.map((a, index) => (
                              <li
                                key={index}
                                className="font-weight-light font-15 font-family cursor-pointer p-1"
                              >
                                <a
                                  href={a.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={a.image}
                                    className="social-icon cursor-pointer"
                                    alt=""
                                  />

                                  {/* <img src={TringleIcon} className="icon-tringle" alt="" /> */}
                                  {/* &nbsp;{a.title} */}
                                </a>
                              </li>
                            ))}

                            {/* <li><img src={Facebbok} className="social-icon cursor-pointer" alt="" /></li>
      <li><img src={Instagram} className="social-icon" alt="" /></li> */}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-4 d-flex align-items-center">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <span className="font-12 font-weight-medium font-10 font-family">
                    NEWSLETTER
                  </span>
                  <div className="font-18 font-weight-medium font-15 font-family">
                    Join Our Newsletter
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 d-flex justify-content-center align-items-center newletter-main">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    className="w-100"
                  >
                    {/* Display a success or error message */}
                    {submissionStatus === "success" && (
                      <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="alert alert-success">
                            Thank You, We Will Connect You Soon !
                          </div>
                        </div>
                      </div>
                    )}
                    {submissionStatus === "error" && (
                      <div className="alert alert-danger">
                        Error sending data. Please try again later.
                      </div>
                    )}
                    <div className="input-group">
                      <Form.Group className="d-flex">
                        <InputGroup hasValidation className="w-100">
                          <Form.Control
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control mt-0 input-lg"
                            required
                            aria-describedby="inputGroupPrepend"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter an Email Address.
                          </Form.Control.Feedback>
                        </InputGroup>
                        <button className="subbgcolor" type="submit">
                          Subscribe
                        </button>
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="row">
                <div className="d-flex justify-content-end font-14 font-weight-medium font-1 font-family align-items-center ftbottomsize">
                  <span className="font-weight-medium font-12 font-family">
                    @2023, made with Passion by  Shree Mahalasa & Company with{" "}
                    <img
                      src={RedHeart}
                      className="redheart-icon cursor-pointer"
                      alt=""
                    />{" "}
                    From Nashik
                  </span>
                </div>
                <div className="d-flex justify-content-center d-lg-none d-block">
                <p className="font-weight-medium font-12 font-family">
  Designed and Developed By{' '}
  <Link to="https://sumagoinfotech.com/" target="_blank">
    <img src={SMLogo} className="img-fluid" alt="" style={{ width: '18px' }} />
  </Link>
</p>

</div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 footer-right d-none  d-lg-block">
<div className="d-flex justify-content-center">
<p className="position-absolute bottom-0">
  <b>Designed and Developed By </b>{" "}
  {/* <a href="https://sumagoinfotech.com/" target="_blank">
    <img src={SMLogo} className="img-fluid" alt="" style={{ width: '30px' }} />
  </a> */}
   <Link to="https://sumagoinfotech.com/" target="_blank">
  <img src={SMLogo} className="img-fluid" alt="" style={{ width: '24px' }} />
</Link>
</p>

</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
